import { useRouter } from 'next/router'
import React from 'react'

import { Layout } from '@/components/layout'
import { Button } from '@/components/nopk-ui-kit/buttons'
import { GridColumn, GridContainer } from '@/components/nopk-ui-kit/grid'

const NotFound: React.FC = () => {
  const router = useRouter()
  return (
    <Layout title="Page Not Found">
      <GridContainer>
        <GridColumn>
          <div className="flex flex-col items-center justify-center">
            <h1 className="mb-3 text-lg font-semibold text-green">
              This page could not be found.
            </h1>

            <p className="mb-3 font-semibold text-green">
              Apologies but we couldn&apos;t locate the page you trying to
              access. (Code 404)
            </p>

            <Button
              text="Return to Search"
              onClickCallback={() => router.push('/')}
            />
          </div>
        </GridColumn>
      </GridContainer>
    </Layout>
  )
}

export default NotFound
